import util from "../_helpers/util" ;
const _http = (options, type) => {
  var type = type || "GET",
    url = options.url || "/",
    data = options.data || {},
    config = {
      method: type
    };
  if ("GET" === type) {
    if (Object.keys(data).length) {
      url += "?" + http.parseUrl(data);
    }
  } else {
    config["body"] = util.toFormData(data);
  }
  config["headers"] = util.headers();
  return fetch(url, config);
};
export const http = {
  parseUrl: obj => {
    var str = "";
    for (var key in obj) {
      if (str != "") {
        str += "&";
      }
      str += key + "=" + encodeURIComponent(obj[key]);
    }
    return str;
  },
  get: options => {
    return _http(options, "GET");
  },
  post: options => {
    return _http(options, "POST");
  }
};
