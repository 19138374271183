export const alertActions = {
  success: (message) => {
    return { type: 'ALERT_SUCCESS', message };
  },
  error: (message) => {
    return { type: 'ALERT_ERROR', message };
  },
  clear: () => {
    return { type: 'ALERT_CLEAR'};
  },
};
