import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Link,
  Redirect
} from "react-router-dom";
import { FormHeader } from "../formHeader/formHeader";
import "./aplicationform.scss";
import { apis } from "../../actions";
import util from "../../_helpers/util";
import moment from 'moment';

class Aplications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoaded: !1,
      applications:[],
      application:null
    };
  }
  componentDidMount() {
    // NVTS:Site ID #2
    this.props.dispatch(apis.getaplications(2));
  }
  componentWillReceiveProps(nextprops) {
    if (nextprops.api && nextprops.api.UNAUTHROIZED) {
      localStorage.clear();
      window.location.assign("/");
    }
    if (nextprops.api && nextprops.api.aplications) {
      this.setState(
        {
          aplications: nextprops.api.aplications,
          application: nextprops.api.aplications.data[0]||null,
          dataLoaded: !0
        },
        () => {}
      );
    }
  }
  tab(index) {
    var tabs = [
      "personal-info",
      "preferences",
      "qualification",
      "experience",
      "driver-qualification",
      "legal-remarks"
    ];
    var str = tabs[index - 1];
    if (undefined !== str && str !== "") return str;
    return tabs[0];
  }
  status(status) {
    if (2 == status) {
      return "Completed";
    }
    if (3 == status) {
      return "In review";
    }
    if (1 == status) {
      return "Received";
    }
    if (0 == status || 10 == status) {
      return "Denied";
    }
    if (-1 == status) {
      return "Incomplete";
    }
    if (4 == status) {
      return "Offered Position";
    }
    return "";
  }
  render() {
    if (
      this.state.aplications &&
      this.state.aplications.data &&
      !this.state.aplications.data.length
    ) {
      return <Redirect to="/new-application/personal-info" />;
    }
    return (
      <div className="aplcn-body">
        <div className="container">
          <FormHeader back={false} active={this.props.tab} hidetabs={true} />
          {!this.state.dataLoaded && (
            <div className="aplcn-form-data-prefetch">Loading list..</div>
          )}

          {this.state.dataLoaded && (
            <div className="row-s row-main">
              {this.state.aplications &&
                this.state.aplications.data &&
                this.state.aplications.data.map(item => {
                  return (
                    <div key={"aplication_" + item.hash} className="aplcn-list-inner-wrapper">
                      <div className="row aplcn-block-aplication">
                        <div className="col-12 col-xs-12 col-md-3 aplcn-name-block">
                          <div className="aplcn-name-box">
                            <span
                              data-label={util.avatar(
                                item.first_name + " " + item.last_name
                              )}
                              className="aplcn-name-wrapper"
                            >
                              {item.first_name} {item.last_name}
                            </span>
                          </div>
                        </div>
                        <div className="col-12 col-xs-12 col-md-2 aplcn-detail-block aplcn-detail-name-block">
                          <div className="aplcn-row-split">Status</div>
                          <div
                            className={
                              "aplcn-row-split status status-" +
                              this.status(item.status)
                            }
                          >
                            {this.status(item.status)}
                          </div>
                        </div>

                        <div className="col-12 col-xs-12 col-md-2 aplcn-detail-block">
                          <div className="aplcn-row-split">
                            Submitted on
                          </div>
                          <div className="aplcn-row-split">
                            {util.formatedDate(item.date)}
                          </div>
                        </div>

                        <div className="col-12 col-xs-12 col-md-2 aplcn-detail-block aplcn-detail-block-end">
                          <div className="aplcn-row-split">
                            Last Edited
                          </div>
                          <div className="aplcn-row-split">
                            {util.formatedDate(item.updated_at)}
                          </div>
                        </div>
                        <div className="col-12 col-xs-12 col-md-2 aplcn-detail-block aplcn-detail-block-end">
                        <Link
                            className="aplcn-open-aplication-tab"
                            to={
                              "/" +
                              item.hash +
                              "/" +
                              this.tab(item.step)
                            }
                          >
                            { new Date(item.date).getFullYear() < new Date().getFullYear()?(
                              <button className="green-btn">
                              <span className="aplcn-button-label-edit">
                                Reapply For {new Date().getFullYear()+1} Season
                              </span>
                              </button>
                            ):(
                              <div>
                              <i className="fa fa-edit aplcn-edit-icon" />{" "}
                              <span className="aplcn-button-label-edit">
                                Edit Application
                              </span>
                              </div>
                            )}
                          </Link>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          )}
          {
            this.state.application != null && 
            this.state.application.history.length != 0 && 
            <div className="history-list">
            <div className="row">
              <div className="col-md-12">
                <h4 className="title">Previous Application History</h4>
              </div>
            </div>
              <div className="row">
              { 
                this.state.application.history.map((history,index)=>{
                  return <div className="col-md-12 history-item">
                  <div className="row-wrap">
                    <div className="row">
                      <div className="col-sm-4 col-md-3">
                        <div className="sub-title">
                          {index==0?'Last ':''}Application Status
                        </div>
                        <div className="desc">
                          {this.status(history.status)}
                        </div>
                      </div>
                      <div className="col-sm-4 col-md-9">
                      <div className="sub-title">
                        {index==0?'Last ':''}Applied Date
                      </div>
                      <div className="desc">
                        {moment(history.date).format('DD-MMM-YYYY')}
                      </div>  
                      </div>
                    </div>
                  </div>
                </div>
                })
              }
              </div>
          </div>
          }
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return state;
}
const connectedApp = connect(mapStateToProps)(Aplications);
export { connectedApp as Aplications };
