const userReducer = (state = {}, action) => {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return {
        ...state,
        loggingIn: true,
        auth: action.data,
        action: "LOGIN",
      };
    case "LOGIN_FAIL":
      return {
        ...state,
        loggingIn: false,
        auth: action.data,
        action: "LOGIN",
      };
    case "REGISTER_SUCCESS":
      return {
        ...state,
        auth: action.data,
        action: "REGISTER",
      };
    case "USERS_RESET_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "USERS_RESET_SUCCESS":
      return {
        ...state,
        loading: true,
        changePwd: action.changePwd,
      };
    case "USERS_RESET_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case "USERS_PASSWORD_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "USERS_PASSWORD_SUCCESS":
      return {
        ...state,
        newPwd: action.newPwd,
      };
    case "USERS_PASSWORD_FAILURE":
      return {
        ...state,
        error: action.error,
      };
  }
  return state;
};
export default userReducer;
