import React, { Component } from "react";
import { connect } from "react-redux";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { Login } from "./components/login/login";
import { AplicationForm } from "./components/form/aplicationForm";
import { history } from "./_helpers/history";
import { PrivateRoute } from "./_helpers/privateRoute";
import { Page404 } from "./components/404/404";
import { Header } from "./components/header/header";
import { Footer } from "./components/footer/footer";
import { Aplications } from "./components/aplications/aplications";
import { ForgotPassword } from './components/forgotPassword/forgotPassword';
import { ResetPassword } from './components/resetPassword/resetPassword'
class App extends Component {
  constructor(props) {
    super(props);
    const { dispatch } = this.props;
    history.listen((location, action) => {});
    sessionStorage.removeItem("tabHasChanges");
  }
  render() {
    const router = (
      <Switch>
        <PrivateRoute exact path="/" component={() => <Redirect to={{ pathname: "/applications" }}></Redirect>} />
        <PrivateRoute
          exact
          path="/:hash/personal-info"
          component={AplicationForm}
          tab="1"
        />
        <PrivateRoute
          exact
          path="/:hash/preferences"
          component={AplicationForm}
          tab="2"
        />
        <PrivateRoute
          exact
          path="/:hash/qualification"
          component={AplicationForm}
          tab="3"
        />
        <PrivateRoute
          exact
          path="/:hash/experience"
          component={AplicationForm}
          tab="4"
        />
        <PrivateRoute
          exact
          path="/:hash/driver-qualification"
          component={AplicationForm}
          tab="5"
        />
        <PrivateRoute
          exact
          path="/:hash/legal-remarks"
          component={AplicationForm}
          tab="6"
        />
        <PrivateRoute
          exact
          path="/applications"
          component={Aplications}
          tab="6"
        />
        <Route path="/login" component={Login} />
        <Route path="/forgot_password" component={ForgotPassword} />
        <Route exact path="/reset_password/:token" component={ResetPassword} />
        <Route component={Page404} />
      </Switch>
    );
    var page = window.location.href.split("/").pop();
    return (
      <Router history={history}>
        <div className="aplcn-page-container-main-wrapper">
          <Header />
          {router}
          <Footer />
        </div>
      </Router>
    );
  }
}
function mapStateToProps(state) {
  return state;
}
const connectedApp = connect(mapStateToProps)(App);
export { connectedApp as App };
