import React from "react";
import ReactDOM from "react-dom";
import store from "./store";
import { Provider } from "react-redux";
import { App } from "./App";
import { config } from "./_config/_config";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import "./scss/index.scss";
import "./scss/_fonts.scss";
import "react-datepicker/dist/react-datepicker.css";

Sentry.init({
  dsn: config.dsn,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);