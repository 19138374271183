import { http } from "../_services/http";
import { config } from "../_config/_config";
export const api = {
  getList: () => {
    return http.get({
      data: {},
      url: config._api("getlistall"),
    });
  },
  deleteFile: (data) => {
    return http.post({
      data: data,
      url: config._api("applicant/deletefile"),
    });
  },
  login: (data) => {
    return http.post({
      data: data,
      url: config._api("applicant/login"),
    });
  },
  getaplications: (siteId) => {
    let URL = "applicant/getapplications";
    if (siteId) {
      URL = URL + `?site_id=${siteId}`
    }
    return http.get({
      url: config._api(URL),
    });
  },
  save: (data) => {
    return http.post({
      data: data,
      url: config._api("applicant/save"),
    });
  },
  getaplicationDeail: (data) => {
    var url = "applicant/applicantdetails";
    if (data.hash) {
      url += "/" + data.hash;
    }
    return http.get({
      url: config._api(url),
    });
  },
  resetPassword: (data) => {
    const requestOptions = {
      method: "POST",
      headers: { Accept: "application/json" },
      body: data,
    };
    return fetch(config.apiUrl + "/api/password/email", requestOptions).then(handleResponse, handleError);
  },
  newPassword: (data) => {
    const requestOptions = {
      method: "POST",
      headers: { Accept: "application/json" },
      body: data,
    };
    return fetch(config.apiUrl + "/api/password/reset", requestOptions).then(handleResponse, handleError);
  },
  logout: () => {
    // remove user from local storage to log user out
    localStorage.removeItem("user");
  },
};

function handleResponse(response) {
  if (response.status == 401) {
    localStorage.removeItem("user");
    window.location.href = "/login";
  } else {
    return new Promise((resolve, reject) => {
      if (response.ok) {
        // return json if it was returned in the response
        var contentType = response.headers.get("content-type");
        if (contentType && contentType.includes("application/json")) {
          response.json().then((json) => resolve(json));
        } else {
          resolve();
        }
      } else {
        // return error message from response body
        response.text().then((text) => reject(text));
      }
    });
  }
}

function handleError(error) {
  return Promise.reject(error && error.message);
}
