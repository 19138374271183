import moment from "moment";
import { confirmAlert } from "react-confirm-alert";
class Util {
  constructor() {
    this._classes = {
      blur: "aplcn-blurred",
      error: "aplcn-has-error"
    };
    this.validations = {
      /*validation hooks*/
      phonenumber: target => {
        /*phone number validation function*/
        let val = target.value || "";
        if (val.length !== 14) {
          this.showError(target);
          return !1;
        } else {
          this.showError(target, !1);
          return !0;
        }
      },
      password: function(target) {
        /*password validation*/
        if (!target) return !0;
        var val = target.value || "";
        if (!val) {
          this.showError(target);
          return !1;
        } else {
          this.showError(target, !1);
          return !0;
        }
        return !0;
      }
    };
    this.filters = {
      /*filter hooks for form values*/
      checkbox: item => {
        return (item || "").split(",");
      },
      date: date => {
        var date = new Date(date);
        if (moment(date).isValid()) {
          return date;
        }
        return "";
      }
    };
  }
  extractObjectAndMerge(obj, map, keys) {
    var map = map || {},
      keys = keys || [],
      i = 0,
      length = keys.length,
      ele = null,
      obj = obj || {};
    for (; i < length; i++) {
      ele = obj[keys[i]];

      if (this.isObject(ele)) {
        for (var key in ele) {
          var name = key,
            innerValue = ele[name];
          if (map.hasOwnProperty(name)) {
            var join = i + 1;
            if (this.isObject(map[name])) {
              name = map[name]["name"];
            } else {
              name = Array.isArray(map[name])
                ? map[name].join(join)
                : map[name] + join;
            }
            obj[name] =
              this.formatedDate(innerValue) === innerValue
                ? new Date(innerValue)
                : innerValue;
          }
        }
      }
      try {
      } catch (err) {}
    }
    return obj;
  }

  _extractObjectAndMerge(obj, map, keys) {
    var map = map || {},
      keys = keys || [],
      i = 0,
      length = keys.length,
      ele = null,
      obj = obj || {};
    for (; i < length; i++) {
      ele = obj[keys[i]];
      if (this.isObject(ele)) {
        for (var key in ele) {
          var name = key,
            innerValue = ele[name];
          if (map.hasOwnProperty(name)) {
            var join = i > 0 ? i : "";
            if (this.isObject(map[name])) {
              name = map[name]["name"];
            } else {
              name = Array.isArray(map[name])
                ? map[name].join(join + 1)
                : map[name] + join;
            }
            obj[name] =
              this.formatedDate(innerValue) === innerValue
                ? new Date(innerValue)
                : innerValue;
          }
        }
      }
      try {
        //  delete obj[keys[i]];
      } catch (err) {}
    }
    return obj;
  }


  formatValue(value) {
    /*format form value befor send 
      if the value is date object then convert it to common date format
    */
    var value = value || "";
    if ("string" === typeof value) {
      return value;
    }
    if (value instanceof Date) {
      return this.formatedDate(value);
    }
    return value;
  }
  toFormData(data) {
    /*
    convert object to form data 
    */

    var formdata = new FormData(),
      val = null;
    for (var name in data) {
      val = data[name];
      if (Array.isArray(val)) {
        /*
        check if value is array because
        checkbox values are stored in array 
        */
        val = val.filter(item => {
          return typeof item === "string" && item.trim() !== "";
        });
        /*if the value is array then it's converted to comma seperated values */
        formdata.append(name, val.join(","));
      } else {
        if (this.isObject(val)) {
          /*
          if upload contains file objects then its passed as object
          */
          var files = val["files"] || !1;
          if (files) {
            var name = val["name"],
              length = files.length,
              i = 0;
            for (; i < length; i++) {
              formdata.append(name + "[]", files[i].originalFile);
            }
          }
        } else {
          formdata.append(name, this.formatValue(val));
        }
      }
    }
    return formdata;
  }
  headers() {
    /*
    Create headers
    */
    var obj = {},
      user = localStorage.getItem("__user");
    if (user) {
      try {
        user = JSON.parse(user);
        if (user.tocken) {
          obj["Authorization"] = "Bearer " + user.tocken;
        }
      } catch (err) {}
    }
    // obj[  "Content-Type" ] = "multipart/form-data" ;
    return obj;
  }
  updateUserTocken(tocken, action) {
    /*
    updating user tocken
    */
    var obj = {
      tocken: tocken
    };
    localStorage.setItem("__user", JSON.stringify(obj));
    this.setState({
      loginSuccess: action
    });
  }
  isLoggedIn() {
    /*
    check if the user is logged in or not
    */
    var user = localStorage.getItem("__user");
    if (!user) return !1;
    try {
      user = JSON.parse(user);
      if (user.tocken) {
        return !0;
      } else {
        return !1;
      }
    } catch (err) {
      return !1;
    }
    return !1;
  }

  parseMessage(error) {
    /*
    extract error message from json Object
    sometimes json returns string and sometimes it will be array with morethan one item
    */
    if ("string" === typeof error) {
      return error;
    }
    var message = [];
    if (this.isObject(error)) {
      var obj = null;
      for (var key in error) {
        obj = error[key];
        if (typeof obj === "string") {
          message.push(obj);
        }
        if (Array.isArray(obj)) {
          obj.map(item => {
            message.push(item);
          });
        }
      }
    }
    if (message.length) {
      return message.join(" ");
    }
    return "";
  }

  fileSize(bytes, si) {
    /*
    convert files size to readable format
    */
    var thresh = si ? 1000 : 1024;
    if (Math.abs(bytes) < thresh) {
      return bytes + " B";
    }
    var units = si
      ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
      : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
    var u = -1;
    do {
      bytes /= thresh;
      ++u;
    } while (Math.abs(bytes) >= thresh && u < units.length - 1);
    return bytes.toFixed(1) + " " + units[u];
  }

  formatedDate(date, format) {
    /*
    convert date to common format
    */
    var date = date || new Date();
    return moment(date).format(format || this.dateFormat());
  }
  dateFormat() {
    /*
    returns common date format
    */
    return "MM/DD/YY";
  }
  _datePickerFormat() {
    return "MM/dd/yy";
  }
  avatar(_name) {
    /*
    generates users default thumbanil text 
    */
    var name = [];
    (_name || "")
      .split(" ")
      .splice(0, 2)
      .map(item => {
        return name.push(item[0]);
      });
    return name.join("").toUpperCase();
  }

  isObject = function(target) {
    /*
  Check if variable is javascript object or not
  */
    return !!target && target.constructor === Object;
  };

  defaultChecked(item, array) {
    /*
     check if checkbox value is in array or not
     */
    var array = Array.isArray(array) ? array : [];
    if (item === "") return false;
    return array.indexOf(item) > -1 || array.indexOf(item.toString()) > -1
      ? true
      : false;
  }
  extension(filename) {
    return filename.split(".").pop();
  }
  _merge(obj1, obj2, map, callback) {
    /*
  Merge fetched value and current object
  params
  obj1 : form object 
  obj2 : object from server
  */
    var obj = null,
      map = map || {},
      res = {},
      callback = callback || null;
    for (var key in obj1) {
      /*
      check for custom filter or key maping
      */
      if (map.hasOwnProperty(key)) {
        var config = map[key]; /* map object*/
        if (config && typeof config === "string") {
          /*Map new key form config object*/
          obj = obj2[config];
        }
        if (this.isObject(config)) {
          /*if config is object 
          check for filter function
          { targt : {map key },filter:{filter function}}
          */
          var _key = config.target || key,
            filter = config.filter || null,
            callback = config.callback || null;
          obj = obj2[_key];
          if (filter && typeof filter === "function") {
            obj = filter.call(this, obj);
          } else if (filter && typeof filter === "string") {
            if (this.filters.hasOwnProperty(filter)) {
              obj = this.filters[filter].call(this, obj);
            }
          }
        }
      } else {
        /*if no mapping and filter function is applied*/
        obj = obj2[key];
      }
      var val = obj || obj1[key];
      res[key] = val != null && "null" !== val ? val : "";
    }
    if (typeof callback === "function") {
      res = callback(res);
    }
    return res;
  }

  setValue(event, isdate) {
    /*
    Set input value on change
    */
    var event = event || null;
    if (isdate) {
      var ele = document.createElement("input");
      ele.setAttribute("type", "text");
      ele.setAttribute("name", isdate.name);
      event = {
        target: ele
      };
    }
    var state = Object.assign(this.state, {}),
      target = event.target,
      type = target.getAttribute("type"),
      name = target.getAttribute("name").split("."),
      value = !isdate ? target.value : isdate.value,
      initial = null;
    if (name.length === 1) {
      initial = state[name[0]];
    }
    if (name.length === 2) {
      initial = state[name[0]][name[1]];
    }
    /*check element is checkbox*/

    if ("checkbox" === type) {
      var splice = !target.checked;
      if (!Array.isArray(initial)) {
        initial = [];
      }
      if (!splice) {
        initial.push(value);
      } else {
        initial.splice(initial.indexOf(value), 1);
      }
    } else {
      initial = value;
    }

    if (name.length === 1) {
      state[name[0]] = initial;
    }
    if (name.length === 2) {
      state[name[0]][name[1]] = initial;
    }
    this.setState(state, () => {
      // this.setState({
      //   __changes: true
      // });
      sessionStorage.setItem("tabHasChanges", true);
    });
  }
  toLink(name) {
    /*
    convert string to url friendly text
    */
    return (name || "")
      .toLowerCase()
      .split(" ")
      .join("-");
  }
  validate(target) {
    /*
    validate input fields
    */
    if (!target) return;
    var type = target.getAttribute("type"),
      hook = target.getAttribute("data-validation"),
      value = (target.value || "").trim(),
      required = target.hasAttribute("required");
    if (!required && "email" !== type) return !0;
    if ("email" === type) {
      /*validate email*/
      if (!this.email(value)) {
        this.showError(target);
        return !1;
      } else {
        this.showError(target, !1);
        return !0;
      }
    }
    if (this.validations.hasOwnProperty(hook)) {
      /*
      check for any validation hooks
      */
      return this.validations[hook].call(this, target);
    }
    if (required && !value) {
      this.showError(target);
      return !1;
    } else {
      this.showError(target, !1);
      return !0;
    }
    return !0;
  }
  hasErrors(form) {
    /*
    Checking  input has error class 
    */
    var errorFields = form.getElementsByClassName(this._classes.error);
    if (errorFields.length) return !0;
    return !1;
  }
  forceVlidate(form) {
    /*
    validate all the form fields
    Params  :Form Element
    */
    if (this.hasErrors(form)) return !1;
    var inputs = form.querySelectorAll("input,textarea,select"),
      length = inputs.length,
      i = 0,
      validate = null,
      ele,
      hasErrors = !1,
      missingFields = [];
    for (; i < length; i++) {
      ele = inputs[i];
      if (ele.classList.contains(this._classes.blur)) continue;
      validate = this.validate(ele);
      if (!validate) {
        hasErrors = !0;
        missingFields.push(ele.getAttribute("title"));
      }
    }
    if (hasErrors) {
      this.showErrorAlert(missingFields);
      return !1;
    }
    return !0;
  }
  showErrorAlert(data) {
    /*
    Showing alert
    */
    if (!data || !data.length) return;
    confirmAlert({
      title: "Missing fields",
      message: data.join(",") + " are missing!",
      buttons: [
        {
          label: "Close",
          onClick: () => {}
        }
      ]
    });
  }
  showError(target, type = true) {
    /*
    Highlight error on input
    */
    if (!target) return;
    target.classList[type ? "add" : "remove"](this._classes.error);
  }

  blur(event) {
    var target = event.target;
    target.classList.add(this._classes.blur);
    this.validate(target);
  }
  email(email) {
    /*Email validation*/
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/.test(email)) {
      return true;
    }
    return false;
  }
  isblurred(target) {
    /*
    creheck if element is blurred or not
    */
    if (!target) return !1;
    return !!target.classList.contains(this._classes.blur);
  }
}
export default (Util = new Util());
