import React, { Component } from "react";
import { connect } from "react-redux";
import "./404.scss";
class Page404 extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="aplcn-page-404">
        <div className="aplcn-404-title">404 page not found!</div>
        <div className="aplcn-404-message">We are sorry but the page you are looking for does not exist.</div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return state;
}
const connectedApp = connect(mapStateToProps)(Page404);
export { connectedApp as Page404 };
