import React, { Component } from "react";
import { connect } from "react-redux";
import "./preferences.scss";
import util from "../../_helpers/util";

const step = 2 ;
const page = "preferences" ;
class Preferences extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validators:{
        applying_position:null
      },
      form: {
        // anticipatedLocationList : "" , // Disabled for NVAM clone
        // reloacationDate : "" , // Disabled for NVAM clone
        // chkPositionOn : "" , // Disabled for NVAM clone
        applying_position : [] ,
        reporting_location : "",
        chkCurrentQualification : "" ,
        step : step,
        applyingCompany:"2",
      }
    };
  }

  blur(event) {
    util.blur(event);
  }

  setValue(event) {
    const name = event.target.name.split('.')[1];
    if(event.target.type === 'checkbox'){
      this.setState({validators: {[name]:event.target.value}});
    }
    util.setValue.call(this, event);
    if (util.isblurred(event.target)) {
      const valid = util.validate(event.target);
    }
  }

  handleDateChange(name, date, event) {
    util.setValue.call(
      this,
      null,
      {
        value: date,
        name: name
      },
      () => {
        if (util.isblurred(event.target)) {
          var valid = util.validate(event.target);
        }
      }
    );
  }
  submitForm(event) {
    event.preventDefault();
    if (!util.forceVlidate(document.getElementById( page ))) {
      return;
    }
    "function" === typeof this.props.action &&
      this.props.action({...this.state.form, step},step);
  }

  componentDidMount() {
    if (this.props.api && this.props.api.aplicationDetail) {
      var data = this.props.api.aplicationDetail,
        obj = Object.assign({}, this.state.form);
      this.setState({
        form: util._merge(obj, data, {
         applying_position : {
           target : "applying_position_id" ,
           filter : "checkbox"
         },
        //  chkPositionOn : { // Disabled for NVAM clone
        //    target : "position_on_id" ,
        //    filter : "checkbox"
        //  },
         chkCurrentQualification : { 
          target : "qualification_id",
          filter : "checkbox"
         },
         reporting_location : "reporting_location_id" ,
        //  anticipatedLocationList :"anticipated_location_id" , // Disabled for NVAM clone
        //  reloacationDate : { // Disabled for NVAM clone
        //    target : "anticipated_relocation_date" ,
        //    filter : "date"
        //  }
        })
      });
    }
  }
  shouldBlockNavigation(){

  }
  render() {
    var prefetchedData = this.props.api || {};
    return (
        <div className="aplcn-form aplcn-form-two">
         <form
           method="post"
           onSubmit={this.submitForm.bind(this)}
           id={page }
         >
          {/* <div className="aplcn-form-section-head"> // Removed as a part of NVAM clone
            <h4>Select Anticipated Living Location For Season*</h4>
          </div>
          <div className="aplcn-form-row-main aplcn-has-inputs">
            <div className="row aplcn-row">
              <div className="col-sm-12 col-md-6 aplcn-inp-wrap">
                <select
                  onBlur={this.blur.bind(this)}
                  onChange={this.setValue.bind(this)}
                  className=""
                  required
                  name="form.anticipatedLocationList"
                  value={this.state.form.anticipatedLocationList}
                >
                  <option value=""> --- Select Location --- </option>
                  {prefetchedData.AnticipatedLocation &&
                    prefetchedData.AnticipatedLocation.map((item, index) => {
                      return (
                        <option
                          value={item.id}
                          key={"anticipated_location_" + item.id}
                        >
                          {item.value}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div className="col-sm-12 col-md-6 aplcn-inp-wrap">
                <DatePicker
                  readonly="true"
                  selected={this.state.form.reloacationDate}
                  onChange={this.handleDateChange.bind(this, "form.reloacationDate")}
                  placeholderText="Anticipated Relocation Date"
                  dateFormat={util._datePickerFormat()}
                  showYearDropdown
                  name="form.reloacationDate"
                />
              </div>
            </div>
          </div> */}
          <div className="aplcn-form-section-head">
            <h4>Position Applying For* (Check all that apply)</h4>
          </div>
          <div className="aplcn-form-row-main">
            <ul className="aplcn-checklist">
              {prefetchedData.ApplyingPosition &&
                prefetchedData.ApplyingPosition.map((item, index) => {      
                  return (
                    <li key={"applying_position_" + item.id}>
                      <input
                        checked={util.defaultChecked(item.id,this.state.form.applying_position)}
                        value={item.id}
                        name="form.applying_position"
                        type="checkbox"
                        onChange={this.setValue.bind(this)}
                        id={"applying_position_" + item.id}
                      />
                      <label htmlFor={"applying_position_" + item.id}>
                        <span className="aplcn-check-label">{item.value}</span>
                      </label>
                    </li>
                  );
                })}
            </ul>
          </div>
          <div className="aplcn-form-section-head">
            <h4>Desired Reporting Location (select one)</h4>
          </div>
          <div className="aplcn-form-row-main">
            <select
              onBlur={this.blur.bind(this)}
              onChange={this.setValue.bind(this)}
              required
              className=""
              name="form.reporting_location"
              value={this.state.form.reporting_location}
              
            >
              <option value="">--- Select Location --</option>
              {prefetchedData.ReportingLocation &&
                prefetchedData.ReportingLocation.map((item, index) => {
                  return (
                    <option value={item.id} key={"rl_" + item.id}>
                      {item.value}
                    </option>
                  );
                })}
            </select>
          </div>
          {/* <div className="aplcn-form-section-head"> // Removed as part of NVAM clone
            <h4>
              I would like to be considered for a position on (Check all that
              apply)
            </h4>
          </div>
          <div className="aplcn-form-row-main">
            <ul className="aplcn-checklist">
              {prefetchedData.PositionOn &&
                prefetchedData.PositionOn.map((item, index) => {
                  return (
                    <li key={"aa_pos" + item.id}>
                      <input
                        name="form.chkPositionOn"
                        type="checkbox"
                        value={item.id}
                        id={"cons_pos_" + item.id}
                        onChange={this.setValue.bind(this)}
                        checked={util.defaultChecked(item.id,this.state.form.chkPositionOn)}
                      />
                      <label htmlFor={"cons_pos_" + item.id}>
                        <span className="aplcn-check-label">{item.value}</span>
                      </label>
                    </li>
                  );
                })}
            </ul>
          </div> */}
          <div className="aplcn-form-section-head">
            <h4>
              Current Qualifications: As Specified In PMS 310-1 NWCG
              Guidelines (Check all that apply)
            </h4>
          </div>
          <div className="aplcn-form-row-main">
            <ul className="aplcn-checklist">
              {prefetchedData.Qualification &&
                prefetchedData.Qualification.map((item, index) => {
                  return (
                    <li key={"quali_" + item.id}>
                      <input
                        name="form.chkCurrentQualification"
                        checked={util.defaultChecked(item.id,this.state.form.chkCurrentQualification)}
                        type="checkbox"
                        id={"quali_" + item.id}
                        onChange={this.setValue.bind(this)}
                        value={item.id}
                      />
                      <label htmlFor={"quali_" + item.id}>
                        <span className="aplcn-check-label">{item.value}</span>
                      </label>
                    </li>
                  );
                })}
            </ul>
          </div>

         <div className="row">
            <div className="col-sm-12 aplcn-form-main-actionbar">
              <div className="aplcn-form-actionbar">
                <button onClick={this.back.bind(this)} type="button" disabled="" className="aplcn-prev-button">
                  Previous
                </button>
                <button type="submit" className="aplcn-next-btn">
                  Next
                </button>
              </div>
            </div>
          </div>

        </form>
        </div>
    );
  }
  back(event){
    event.preventDefault();
    this.props.back( step );
  }
}
function mapStateToProps(state) {
  return state;
}
const connectedApp = connect(mapStateToProps)(Preferences);
export { connectedApp as Preferences };
