import React from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import { userActions } from "../../actions";

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      submitted: false,
      redirect: "",
      alertPop: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  componentDidMount() {
    //reset login status
    this.props.dispatch(userActions.logout());
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    e.target.setAttribute("errors", "");
  }

  componentWillReceiveProps(nextProps) {
    const { loading, changePwd, user } = nextProps;

    if (changePwd) {
      // alert(changePwd.message)
      confirmAlert({
        title: "Success",
        message: changePwd.message,
        buttons: [
          {
            label: "Close",
            onClick: this.goBack(),
          },
        ],
      });
    } else if (!user.loading && user.error) {
      // alert(JSON.parse(user.error).message)
      confirmAlert({
        title: "Error",
        message: JSON.parse(user.error).message,
        buttons: [
          {
            label: "Close",
            onClick: () => {},
          },
        ],
      });
    }
    this.setState({ loading });
  }

  goBack = () => {
    this.props.history.push("/");
  };

  validate(e) {
    let isError = false;
    if (this.state.email === "" || !this.IsValidEmail(this.state.email)) {
      isError = true;
      this.setState({
        emailError: "error",
      });
      document.getElementById("email").focus();
    }
    return isError;
  }

  IsValidEmail(email) {
    var expr = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
    return expr.test(email);
  }
  // parsing url search params based on the key provided
  getQueryStringValue(key) {
    return decodeURIComponent(
      window.location.search.replace(
        new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"),
        "$1"
      )
    );
  }

  // Would write the value of the QueryString-variable called name to the console

  handleSubmit(e) {
    e.preventDefault();
    var err = this.validate();
    this.setState({ submitted: true });
    const { email } = this.state;
    const { dispatch } = this.props;
    let data = new FormData();
    data.append("type", "applicants");
    data.append("email", email);
    data.append("site_id", "2");
    if (!err) {
      dispatch(userActions.resetPassword(data));
    }
  }
  componentDidMount() {
    console.log(this.getQueryStringValue("type"));
  }

  render() {
    // const { loading } = this.props.user;
    const { email, submitted } = this.state;
    if (this.state.redirect === "/") {
      return <Redirect to="/" />;
    }
    return (
      <div className="forgot_pwd_outer reset-password col-12 aplcn-login-box-wrapper">
        <div className="loginForm forgot_pwd aplcn-login-box-inner">
          <p className="nvam-title">Employment Application</p>
          <form name="form" onSubmit={this.handleSubmit}>
            <div className={"form-group" + (submitted && !email ? " has-error" : "")}>
              <label htmlFor="email">Enter your email address below to reset your password.</label>
              <input
                type="text"
                errors={this.state.emailError}
                id="email"
                className="form-control"
                name="email"
                value={email}
                onChange={this.handleChange}
              />
            </div>
            <hr />
            <div className="form-group btnGroup">
              <button className="btn btn-primary">Send Reset Link</button>
              {this.state.loading && (
                <img
                  alt="loader"
                  src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
                />
              )}
            </div>
          </form>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { changePwd, error, loading } = state.user;
  const { user } = state;
  return {
    changePwd,
    error,
    loading,
    user,
  };
}

const connectedLoginPage = connect(mapStateToProps)(ForgotPassword);
export { connectedLoginPage as ForgotPassword };
