import React from "react";
import { Route, Redirect } from "react-router-dom";
import { Login } from "../components/login/login"
export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      localStorage.getItem('__user')
          ? <Component {...props} {...rest} />
          : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    )}
  />
);