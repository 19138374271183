import React from "react";
import { connect } from "react-redux";
import { userActions } from "../../actions/userActions";
import { confirmAlert } from "react-confirm-alert";
import { alertActions } from "../../actions/alertActions";
import queryString from "query-string";
class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "password",
      email: "",
      password: "",
      confirm_password: "",
      submitted: false,
      passwordError: {
        type: "",
        message: "",
      },
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.closeAlert = this.closeAlert.bind(this);
  }

  componentDidMount() {
    //reset login status
    this.props.dispatch(userActions.logout());
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
      passwordError: {
        message: "",
        type: "",
      },
    });
  }

  closeAlert = () => {
    this.setState({
      alertPop: false,
    });
    this.props.dispatch(alertActions.clear());
    if (this.state.alertFunction === "Success") {
      this.props.history.push("/");
    }
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.alert && Object.keys(nextProps.alert).length) {
      this.setState({
        alertPop: true,
        alertHead: nextProps.alert.type === "alert-success" ? "Success" : "Error",
        alertMsg: nextProps.alert.message,
        alertFunction: nextProps.alert.type === "alert-success" ? "Success" : "Error",
      });
    }
  }

  togglePassword() {
    this.setState({
      type: this.state.type === "password" ? "text" : "password",
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    let validate = this.validatePassword();
    if (!validate) {
      return;
    }
    this.setState({ submitted: true });
    const { email, password, confirm_password } = this.state;
    const { dispatch } = this.props;
    let data = new FormData();
    data.append("email", email);
    data.append("password", password);
    data.append("password_confirmation", confirm_password);
    data.append("token", this.props.match.params.token);
    let queryParams = queryString.parse(this.props.location.search);
    let type = queryParams.type || "users";
    data.append("type", type);
    if (email && password && confirm_password) {
      dispatch(userActions.newPassword(data));
    }
  }

  validatePassword() {
    let flag = true;
    let message = "";
    let type = "";
    if (this.state.password && this.state.password.length < 8) {
      flag = false;
      type = 1;
      message = "Password length must be at least 8 characters";
    } else if (String(this.state.password) !== String(this.state.confirm_password)) {
      flag = false;
      type = 2;
      message = "Password and Confirm Password does not match";
    }
    if (!flag) {
      this.setState({
        passwordError: {
          message,
          type,
        },
      });
    } else {
      this.setState({
        passwordError: {
          message: "",
          type: "",
        },
      });
    }
    return flag;
  }

  render() {
    const { loggingIn } = this.props;
    const { email, password, confirm_password, submitted, passwordError } = this.state;
    return (
      <div className="reset_pwd_outer reset-password col-12 aplcn-login-box-wrapper">
        <div className="loginForm reset_pwd aplcn-login-box-inner">
          <p className="nvam-title">Employment Application</p>
          <form name="form" onSubmit={this.handleSubmit}>
            <div className={"form-group" + (submitted && !email ? " has-error" : "")}>
              <label htmlFor="email">Email</label>
              <input type="email" className="form-control" name="email" value={email} onChange={this.handleChange} />
              {submitted && !email && <div className="help-block">Email address is required</div>}
              <label htmlFor="email">Password</label>
              <div className="inline-login-txt-wrapper">
                <input
                  type={this.state.type}
                  className="form-control"
                  name="password"
                  value={password}
                  onChange={this.handleChange}
                />

                <span
                  className="toggle-icon-password"
                  onClick={(event) => {
                    this.togglePassword(event);
                  }}
                  aria-hidden={this.state.type === "password" ? "true" : "false"}
                >
                  <img src={require("../../images/eye.png").default} />
                  <img src={require("../../images/eye-hide.png").default} />
                </span>
              </div>
              {submitted && !password && <div className="help-block">Password is required</div>}
              {submitted && password && passwordError.type === 1 && (
                <div className="help-block">{passwordError.message}</div>
              )}
              <label htmlFor="email">Confirm password</label>
              <input
                type={this.state.type}
                className="form-control"
                name="confirm_password"
                value={confirm_password}
                onChange={this.handleChange}
              />
              {submitted && !confirm_password && <div className="help-block">Confirm password is required</div>}
              {submitted && confirm_password && passwordError.type === 2 && (
                <div className="help-block">{passwordError.message}</div>
              )}
            </div>
            <hr />
            <div className="form-group btnGroup">
              <button className="btn btn-primary">Submit</button>
              {loggingIn && (
                <img
                  alt="loader"
                  src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
                />
              )}
            </div>
          </form>
        </div>
        {this.state.alertPop &&
          confirmAlert({
            title: this.state.alertHead,
            message: this.state.alertMsg,
            buttons: [
              {
                label: "Close",
                onClick: this.closeAlert.bind(this),
              },
            ],
          })}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { loggingIn } = state.user;
  const { alert } = state;
  return {
    loggingIn,
    alert,
  };
}

const connectedLoginPage = connect(mapStateToProps)(ResetPassword);
export { connectedLoginPage as ResetPassword };
