const apiReducer = (state = {}, action) => {
  switch (action.type) {
    case "DATA_SUCCESS":
      return {
        ...state,
        ...action.data
      };
    case "DATA_FAIL":
      return {
        ...state
      };
    case "FETCH_START":
      return {
        ...state
      };
    case "APLICATIONS_UNAUTHROIZED":
      return {
        ...state,
        UNAUTHROIZED: true
      };
      break;
    case "APLICATIONS_SUCCESS":
      return {
        ...state,
        aplications: action.data.data
      };
    case "DETAIL_SUCCESS":
      return {
        ...state,
        aplicationDetail: action.data.data
      };

    case "SAVE_SUCCESS":
      return {
        ...state,
        formLoading: action.data
      };

    case "SAVE_START":
      return {
        ...state,
        formLoading: true
      };
    case "SAVE_FAIL":
      return {
        ...state,
        formLoading: false
      };

    case "HAS_FORM_CHANGES":
      return {
        ...state,
        formHasChanges: action.__formChange
      };

    case "RESET_FORM_LOADING":
      return {
        ...state,
        formLoading: false
      };

    case "FILE_DELETE_SUCCESS":
      return {
        ...state,
        deleteFile: action.data
      };

    case "FILE_DELETE_FAIL":
      return {
        ...state,
        deleteFile: false
      };
  }
  return state;
};
export default apiReducer;
