import { api } from "../_services/api";
export const apis = {
  deleteFile: data => {
    return (dispatch, getState) => {
      dispatch({
        type: "FILE_DELETE_START"
      });
      api.deleteFile(data).then(
        res => {
          res.json().then(json => {
            dispatch({
              type: "FILE_DELETE_SUCCESS",
              data: Object.assign({}, json, { __id: data.id })
            });
          });
        },
        err => {
          dispatch({
            type: "FILE_DELETE_FAIL",
            data: {}
          });
        }
      );
    };
  },
  getListAll: () => {
    return (dispatch, getState) => {
      dispatch({
        type: "FETCH_START"
      });
      api.getList().then(
        res => {
          res.json().then(json => {
            dispatch({
              type: "DATA_SUCCESS",
              data: json
            });
          });
        },
        err => {
          dispatch({
            type: "DATA_FAIL",
            data: {}
          });
        }
      );
    };
  },
  saveForm: data => {
    return (dispatch, getState) => {
      dispatch({
        type: "SAVE_START"
      });
      api.save(data).then(
        res => {
          res.json().then(json => {
            dispatch({
              type: "SAVE_SUCCESS",
              data: json
            });
          });
        },
        err => {
          dispatch({
            type: "SAVE_FAIL",
            data: {}
          });
        }
      );
    };
  },
  getaplications: (siteId) => {
    return (dispatch, getState) => {
      dispatch({
        type: "APLICATIONS_START"
      });

      api.getaplications(siteId).then(
        res => {
          if (res.status == 401) {
            dispatch({
              type: "APLICATIONS_UNAUTHROIZED",
              data: {}
            });
          } else {
            res.json().then(json => {
              dispatch({
                type: "APLICATIONS_SUCCESS",
                data: json
              });
            });
          }
        },
        err => {
          dispatch({
            type: "APLICATIONS_FAIL",
            data: {}
          });
        }
      );
    };
  },
  getForm: hash => {
    return (dispatch, getState) => {
      dispatch({
        type: "DETAIL_START"
      });
      api
        .getaplicationDeail({
          hash: hash
        })
        .then(
          res => {
            res.json().then(json => {
              dispatch({
                type: "DETAIL_SUCCESS",
                data: json
              });
            });
          },
          err => {
            dispatch({
              type: "DETAIL_FAIL",
              data: {}
            });
          }
        );
    };
  },

  hasChanges: changes => {
    return (dispatch, getState) => {
      dispatch({
        type: "HAS_FORM_CHANGES",
        __formChange: changes
      });
    };
  },
  reset: changes => {
    return (dispatch, getState) => {
      dispatch({
        type: "RESET_FORM_LOADING",
        __formChange: false
      });
    };
  }
};
