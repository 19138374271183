import { api } from "../_services/api";
import { alertActions } from "./alertActions";

export const userActions = {
  login: (data) => {
    return (dispatch, getState) => {
      dispatch({
        type: "LOGIN_START",
      });
      api.login(data).then(
        (res) => {
          res.json().then((json) => {
            dispatch({
              type: data.type.toUpperCase() + "_SUCCESS",
              data: json,
            });
          });
        },
        (err) => {
          dispatch({
            type: data.type.toUpperCase() + "_FAIL",
            data: err,
          });
        }
      );
    };
  },
  resetPassword: (data) => {
    return (dispatch) => {
      dispatch(request());
      api.resetPassword(data).then(
        (changePwd) => dispatch(success(changePwd)),
        (error) => {
          dispatch(failure(error));
        }
      );
    };

    function request() {
      return { type: "USERS_RESET_REQUEST" };
    }
    function success(changePwd) {
      return { type: "USERS_RESET_SUCCESS", changePwd };
    }
    function failure(error) {
      return { type: "USERS_RESET_FAILURE", error };
    }
  },
  newPassword: (data) => {
    return (dispatch) => {
      dispatch(request());
      api.newPassword(data).then(
        (data) => {
          if (!data.data) {
            dispatch(alertActions.error(data.message || ""));
          } else {
            dispatch(alertActions.success(data.message || ""));
          }
          dispatch(success(data));
        },
        (error) => {
          dispatch(failure(error));
          error = JSON.parse(error);
          dispatch(alertActions.error(error.message));
        }
      );
    };

    function request() {
      return { type: "USERS_PASSWORD_REQUEST" };
    }
    function success(data) {
      return { type: "USERS_PASSWORD_SUCCESS", data };
    }
    function failure(error) {
      return { type: "USERS_PASSWORD_FAILURE", error };
    }
  },
  logout: () => {
    api.logout();
    return (dispatch) => {
      dispatch({ type: "USERS_LOGOUT" });
    };
  },
};
