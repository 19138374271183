import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "./formHeader.scss";
import { confirmAlert } from "react-confirm-alert";
class FormHeader extends Component {
  constructor(props) {
    super(props);
  }
  generateTaburl(name) {
    return (name || "")
      .trim()
      .toLowerCase()
      .split(" ")
      .join("-");
  }
  logout() {
    
    localStorage.removeItem('__loginTab');
    localStorage.removeItem('__userEmail');
    localStorage.removeItem('__user');

    window.location.assign("/");
    // window.location.reload();
  }
  switchTab(e, url) {
    e.preventDefault();

    if (
      this.props.api &&
      this.props.api.aplicationDetail &&
      1 === this.props.api.aplicationDetail.applicant_status
    ) {
      var hasChanges = sessionStorage.getItem("tabHasChanges") === "true";
      if (!hasChanges) return this.props.history.push(url);
      confirmAlert({
        title: "Save changes",
        message:
          "You have unsaved changes on this page. Do you want to leave this page and discard your changes or stay on this page?",
        buttons: [
          {
            label: "Continue Editing",
            onClick: () => {}
          },
          {
            label: "Leave",
            onClick: () => {
              this.props.history.push(url);
              sessionStorage.removeItem("tabHasChanges");
            }
          }
        ]
      });
    }
    return;
  }
  render() {
    var tabs = {
      1: "Personal info",
      2: "Preferences",
      3: "Qualification",
      4: "Experience",
      5: "Driver Qualification",
      6: "Legal Remarks"
    };
    return (
      <div className="formHeaderWrapper">
        <div className="row">
          <div className="col-sm-12 logout">
            {this.props.back !== false && (
              <Link
                to="/applications"
                className="back-to-applications"
              >
                {" "}
                <i className="fa fa-arrow-left" aria-hidden="true" /> Back
              </Link>
            )}
            <button onClick={this.logout.bind(this)}>
              Logout
            </button>
          </div>
          <div className="col-sm-12">
            <div className="aplcn-header-info">
              <div className=" aplcn-align-center">
                <div className="col-12">
                  <h1>Employment Application</h1>
                </div>
                <div className="col-12">
                  <p>
                    Please fill out all of the necessary fields in this form.
                    Incomplete applications will not be accepted.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div className="aplcn-top-tab-switcher">
              {!this.props.hidetabs && (
                <ul className="">
                  {Object.keys(tabs).map((item, index) => {
                    return (
                      <li
                        className={
                          this.props.active >= item ? "aplcn-active-topbar" : ""
                        }
                        key={"aplcn-tab-sw-" + index}
                      >
                        {(this.props.active >= item || true) && (
                          <a
                            onClick={event => {
                              this.switchTab(
                                event,
                                this.generateTaburl(tabs[item])
                              );
                            }}
                          >
                            <span className="aplcn-tabindex-wrap">0{item}</span>
                            &nbsp;
                            <span className="aplcn-tabname-top">
                              {tabs[item]}
                            </span>
                          </a>
                        )}

                        {1 > 3 && this.props.active < item && (
                          <a>
                            <span className="aplcn-tabindex-wrap">0{item}</span>
                            &nbsp;
                            <span className="aplcn-tabname-top">
                              {tabs[item]}
                            </span>
                          </a>
                        )}
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return state;
}
const connectedApp = connect(mapStateToProps)(FormHeader);
export { connectedApp as FormHeader };
