import React, { Component } from "react";
import { connect } from "react-redux";
import "./qualification.scss";
import util from "../../_helpers/util";
import classNames from "classnames";
import Dropzone from "react-dropzone";
import { filesConfig } from "../../_helpers/acceptedFiles";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { apis } from "../../actions";
const step = 3;
const page = "qualifications";
class Qualification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        // certification_status: "", // Disabled for NVAM clone
        qualifications: "",
        high_school: "",
        school_duration: "",
        school_taken: "",
        //is_school_graduate: [],
        university: "",
        university1: "",
        degree_duration: "",
        degree_duration1: "",
        degree_taken: "",
        degree_taken1: "",
        step: step,
        addition_info: "",
        __uploadedFiles: [],
        is_school_graduate: "no",
        is_degree_graduate: "no",
        is_degree_graduate1: "no",
        applyingCompany:"2",
      },
      // certification_status_name: "", // Disabled for NVAM clone
      files: [],
      uploadError: !1
    };
  }
  submitForm(event) {
    event.preventDefault();
    if (!util.forceVlidate(document.getElementById(page))) {
      return;
    }
    var prefix = "qualification_file",
      form = Object.assign({}, this.state.form);
    if (this.state.files && this.state.files.length) {
      // for (; i < length; i++) {
      //   ele = this.state.files[i];
      //   form[prefix + (i+1)] = ele.originalFile;
      // }
      form[prefix] = {
        files: this.state.files,
        name: prefix
      };
    }

    "function" === typeof this.props.action && this.props.action({...form,step}, step);
  }

  blur(event) {
    util.blur(event);
  }
  setValue(event) {
    util.setValue.call(this, event);
    if (util.isblurred(event.target)) {
      var valid = util.validate(event.target);
    }
  }
  componentDidMount() {
    if (this.props.api && this.props.api.aplicationDetail) {
      var data = this.props.api.aplicationDetail,
        obj = Object.assign({}, this.state.form);
      // this.setState({ certification_status_name: data.certification_status }); // Disabled for NVAM clone
      this.setState({
        form: util._merge(
          obj,
          data,
          {
            applying_position: {
              target: "applying_position_id",
              filter: "checkbox"
            },
            // certification_status: "certification_status_id", // Disabled for NVAM clone
            high_school: "highschool_name",
            qualifications: "general_qualification_id",
            school_duration: "highschool_duration",
            school_taken: "highschool_course",
            is_school_graduate: "highschool_gratuate_status",
            university: "university1",
            university1: "university2",
            addition_info: "applicant_info",
            __uploadedFiles: "filequalification"
          },
          data => {
            /*
          Callback function for modify mapped data
          */
            return this.setUniveristy(data);
          }
        )
      });
    }
  }

  setUniveristy(obj) {
    var obj = obj || {};
    obj = util._extractObjectAndMerge(
      obj,
      {
        name: "university",
        duration: "degree_duration",
        course: "degree_taken",
        gratuate_status: "is_degree_graduate"
      },
      ["university", "university1"]
    );
    return obj;
  }

  addFileToQue(file) {
    if (!file) return;
    var name = file.name || "",
      size = file.size,
      extension = name
        .split(".")
        .pop()
        .toLowerCase();
    if (filesConfig.allowed.indexOf(extension) === -1) {
      /* File type not allowed */
      return {
        status: false,
        type: extension,
        name: name
      };
    }
    return {
      name: name,
      size: util.fileSize(size, true),
      originalFile: file,
      type: extension,
      status: true,
      ready: false
    };
  }
  random(min, max) {
    return Math.round(Math.random() * (max - min) + min);
  }

  removeFileFromQue(index, event) {
    var target = event.currentTarget,
      id = target.getAttribute("data-index"),
      file = this.state.files[index];
    confirmAlert({
      title: "Delete file",
      message: "Are you sure to delete " + file.name,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            var state = Object.assign(this.state, {});
            state.files.splice(index, 1);
            this.setState(state, () => {});
          }
        },
        {
          label: "No",
          onClick: () => {}
        }
      ]
    });
  }

  onDrop(acceptedFiles, rejectedFiles) {
    if (!acceptedFiles || !acceptedFiles.length) return;
    /*check if max items reached*/
    var maxAllowed =
        filesConfig.maxAllowed - this.state.form.__uploadedFiles.length,
      length = acceptedFiles.length,
      i = 0,
      rejected = [],
      accpeted = [],
      maxError = !1,
      queadded = 0,
      message = "",
      currentQue =
        this.state.files && this.state.files ? this.state.files.length : 0,
      merror = "";

    for (; i < length; i++) {
      var _file = this.addFileToQue(acceptedFiles[i]);
      if (currentQue + queadded >= maxAllowed) {
        maxError = !0;
        continue;
      }
      if (!_file.status) {
        rejected.push(_file.name);
      } else {
        accpeted.push(_file);
        queadded++;
      }
    }

    if (rejected.length) {
      message =
        "Error uploading these file" +
        (rejected.length > 1 ? "s (" : " (") +
        rejected.join(",") +
        "). Allowed file formats are (" +
        filesConfig.allowed.join(",") +
        ")";
    }

    if (maxError) {
      merror =
        "you are allowed to add maximum " + filesConfig.maxAllowed + " items.";
      message += message.length ? " and " + merror : merror;
    }
    var obj = Object.assign(this.state, {});
    if (accpeted.length) {
      var array = obj.files || [];
      obj.files = array.concat(accpeted);
      this.setState(obj, () => {});
    }
    message && this.showUploadError(message);
  }
  showUploadError(message) {
    if (message) {
      this.setState(
        {
          uploadError: message
        },
        () => {
          setTimeout(() => {
            this.setState({
              uploadError: false
            });
          }, 5000);
        }
      );
    }
  }

  removeFile(id, name) {
    confirmAlert({
      title: "Delete file",
      message: "Are you sure to delete " + name,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            this.props.dispatch(
              apis.deleteFile({
                id: id
              })
            );
          }
        },
        {
          label: "No",
          onClick: () => {}
        }
      ]
    });
  }
  clearFile(id) {
    var state = Object.assign({}, this.state.form),
      id = id || 0,
      arra = state.__uploadedFiles;
    if (arra && arra.length)
      arra = arra.filter(item => {
        return id !== item.id;
      });
    state.__uploadedFiles = arra;
    this.setState({ form: state });
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.api.deleteFile) {
      var obj = nextProps.api.deleteFile;
      if (util.isObject(obj)) {
        if (obj.success) {
          this.clearFile(obj.__id);
        } else {
          confirmAlert({
            title: "Error Deleting file",
            message: util.parseMessage(obj.message),
            buttons: [
              {
                label: "Close",
                onClick: () => {}
              }
            ]
          });
        }
      }
    }
  }

  render() {
    var prefetchedData = this.props.api || {};
    // if ( // DIsabled for NVAM clone
    //   this.state.form.certification_status &&
    //   prefetchedData.CertificationStatus.findIndex(
    //     item => item.id == this.state.form.certification_status
    //   ) == -1
    // ) {
    //   prefetchedData.CertificationStatus.push({
    //     id: this.state.form.certification_status,
    //     position: this.state.form.certification_status,
    //     value: this.state.certification_status_name
    //   });
    // }

    return (
      <div className="aplcn-form aplcn-form-three">
        <form method="post" onSubmit={this.submitForm.bind(this)} id={page}>
          {/* <div className="aplcn-form-section-head"> // Removed for NVAM clone
            <h4>Current Certification Status*</h4>
          </div>
          <div className="aplcn-form-row-main aplcn-has-inputs">
            <div className="row aplcn-row">
              <div className="col-sm-12 col-md-6 aplcn-inp-wrap">
                <select
                  className=""
                  name="form.certification_status"
                  value={this.state.form.certification_status}
                  onChange={this.setValue.bind(this)}
                  required
                >
                  <option value="">
                    {" "}
                    --- Current Certification Status ---{" "}
                  </option>
                  {prefetchedData.CertificationStatus &&
                    prefetchedData.CertificationStatus.map((item, index) => {
                      return (
                        <option
                          value={item.id}
                          key={"anticipated_location_" + item.id}
                        >
                          {item.value}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div className="col-sm-12 col-md-6 aplcn-inp-wrap">
                <select
                  className=""
                  name="form.qualifications"
                  onChange={this.setValue.bind(this)}
                  value={this.state.form.qualifications}
                  required
                >
                  <option value=""> --- Qualifications --- </option>
                  {prefetchedData.GeneralQualification &&
                    prefetchedData.GeneralQualification.map((item, index) => {
                      return (
                        <option
                          value={item.id}
                          key={"anticipated_location_" + item.id}
                        >
                          {item.name}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
          </div> */}
          <div className="aplcn-form-section-head">
            <h4>Qualification*</h4>
          </div>
          <div className="aplcn-form-row-main aplcn-has-inputs">
            <div className="row aplcn-row">
              <div className="col-sm-12 col-md-6 aplcn-inp-wrap">
                <select
                    className=""
                    name="form.qualifications"
                    onChange={this.setValue.bind(this)}
                    value={this.state.form.qualifications}
                    required
                  >
                    <option value=""> --- Qualifications --- </option>
                    {prefetchedData.GeneralQualification &&
                      prefetchedData.GeneralQualification.map((item, index) => {
                        return (
                          <option
                            value={item.id}
                            key={"anticipated_location_" + item.id}
                          >
                            {item.name}
                          </option>
                        );
                      })}
                </select>
              </div>
            </div>
          </div>

          <div className="aplcn-form-section-head">
            <h4>
              Upload copies of your qualification (taskbooks) and any
              certificates that apply to desired position.
            </h4>
          </div>
          <div className="aplcn-form-row-main">
            <ul className="aplcn-upload-list">
              {this.state.form.__uploadedFiles.length === 0 &&
                this.state.files.length === 0 && (
                  <li className="aplcn-no-files-selected">No files selected</li>
                )}
              {this.state.form.__uploadedFiles &&
                this.state.form.__uploadedFiles.length > 0 &&
                this.state.form.__uploadedFiles.map((item, index) => {
                  return (
                    <li
                      key={"file_list_uploaded" + index}
                      className="aplcn-file-loaded"
                    >
                      <div className="aplcn-inner-upload-wrapper">
                        <div
                          className={
                            "aplcn-upload-type-icon aplcn-" +
                            util.extension(item.file_name.toLowerCase())
                          }
                        />
                        <a
                          className="aplcn-right-file-upload"
                          href="javascript:void(0)"
                          data-index={index}
                          onClick={() => {
                            this.removeFile(item.id, item.file_name);
                          }}
                        >
                          <i className="fa fa-trash" aria-hidden="true" />
                        </a>
                        <div className="aplcn-upload-list-name">
                          {item.file_name}
                        </div>

                        {/* <div className="aplcn-file-uploading-percentage">
                      <div className="aplcn-file-loader">
                        <div className="aplcn-inner-per" />
                      </div>
                    </div> */}

                        <div className="aplcn-file-size">{item.size}</div>
                      </div>
                    </li>
                  );
                })}
              {this.state.files &&
                this.state.files.map((item, index) => {
                  return (
                    <li
                      key={"file_list" + index}
                      className={item.ready ? "aplcn-file-loaded" : ""}
                    >
                      <div className="aplcn-inner-upload-wrapper">
                        <div
                          className={
                            "aplcn-upload-type-icon aplcn-" + item.type.toLowerCase()
                          }
                        />
                        <a
                          className="aplcn-right-file-upload"
                          href="javascript:void(0)"
                          data-index={index}
                          onClick={this.removeFileFromQue.bind(this, index)}
                        >
                          <i className="fa fa-trash" aria-hidden="true" />
                        </a>
                        <div className="aplcn-upload-list-name">
                          {item.name}
                        </div>

                        {/* <div className="aplcn-file-uploading-percentage">
                        <div className="aplcn-file-loader">
                          <div className="aplcn-inner-per" />
                        </div>
                      </div> */}

                        <div className="aplcn-file-size">{item.size}</div>
                      </div>
                    </li>
                  );
                })}
            </ul>
          </div>

          {this.state.form.__uploadedFiles.length < filesConfig.maxAllowed && (
            <div className="aplcn-form-section-block">
              <Dropzone onDrop={this.onDrop.bind(this)}>
                {({ getRootProps, getInputProps, isDragActive }) => {
                  return (
                    <div
                      {...getRootProps()}
                      className={classNames("dropzone", {
                        "dropzone--isActive": isDragActive
                      })}
                    >
                      <input {...getInputProps()} />
                      {isDragActive ? (
                        <p>Drop files here...</p>
                      ) : (
                        <p>
                          Drop files here to upload or <u>choose file</u>
                        </p>
                      )}
                    </div>
                  );
                }}
              </Dropzone>
            </div>
          )}

          {this.state.uploadError && (
            <div className="aplcn-qu-upload-error">
              {this.state.uploadError}
            </div>
          )}
          <div className="aplcn-form-section-head">
            <h4>Education</h4>
          </div>

          <div className="aplcn-form-section-head">
            <h4>High School</h4>
          </div>

          <div className="aplcn-form-row-main  aplcn-has-inputs">
            <div className="row aplcn-row">
              <div className="col-sm-12 col-md-4 aplcn-inp-wrap">
                <input
                  type="text"
                  required
                  placeholder="High School Name & Location"
                  onChange={this.setValue.bind(this)}
                  onBlur={this.blur.bind(this)}
                  value={this.state.form.high_school}
                  name="form.high_school"
                />
              </div>
              <div className="col-sm-12 col-md-4 aplcn-inp-wrap">
                <input
                  type="text"
                  required
                  placeholder="No. Years/Units Completed"
                  onChange={this.setValue.bind(this)}
                  onBlur={this.blur.bind(this)}
                  value={this.state.form.school_duration}
                  name="form.school_duration"
                />
              </div>
              <div className="col-sm-12 col-md-4 aplcn-inp-wrap">
                <input
                  type="text"
                  required
                  placeholder="Degree/Courses Taken"
                  onChange={this.setValue.bind(this)}
                  onBlur={this.blur.bind(this)}
                  value={this.state.form.school_taken}
                  name="form.school_taken"
                />
              </div>
            </div>
          </div>

          <div className="aplcn-form-section-head">
            <h5 className="aplcn-is-graduate">Did you graduate</h5>
          </div>
          <div className="aplcn-form-row-main">
            <ul className="aplcn-checklist inline-radios">
              <li>
                <input
                  value="yes"
                  type="radio"
                  id="is_school_graduate"
                  name="form.is_school_graduate"
                  onChange={this.setValue.bind(this)}
                  onBlur={this.blur.bind(this)}
                  checked={this.state.form.is_school_graduate === "yes"}
                />
                <label htmlFor="is_school_graduate">
                  <span className="aplcn-check-label">Yes</span>
                </label>
              </li>
              <li>
                <input
                  value="no"
                  type="radio"
                  id="is_school_graduate2"
                  name="form.is_school_graduate"
                  onChange={this.setValue.bind(this)}
                  onBlur={this.blur.bind(this)}
                  checked={this.state.form.is_school_graduate !== "yes"}
                />
                <label htmlFor="is_school_graduate2">
                  <span className="aplcn-check-label">No</span>
                </label>
              </li>
            </ul>
          </div>

          <div className="aplcn-form-section-head">
            <h4>University / Tech School</h4>
          </div>

          <div className="aplcn-form-row-main  aplcn-has-inputs">
            <div className="row aplcn-row">
              <div className="col-sm-12 col-md-4 aplcn-inp-wrap">
                <input
                  type="text"
                  placeholder="University/tech name and location*"
                  onChange={this.setValue.bind(this)}
                  onBlur={this.blur.bind(this)}
                  value={this.state.form.university}
                  name="form.university"
                />
              </div>
              <div className="col-sm-12 col-md-4 aplcn-inp-wrap">
                <input
                  type="text"
                  placeholder="No. years/units completed"
                  onChange={this.setValue.bind(this)}
                  onBlur={this.blur.bind(this)}
                  value={this.state.form.degree_duration}
                  name="form.degree_duration"
                />
              </div>
              <div className="col-sm-12 col-md-4 aplcn-inp-wrap">
                <input
                  type="text"
                  placeholder="Degreee/course taken"
                  onChange={this.setValue.bind(this)}
                  onBlur={this.blur.bind(this)}
                  value={this.state.form.degree_taken}
                  name="form.degree_taken"
                />
              </div>
            </div>
          </div>

          <div className="aplcn-form-section-head">
            <h5 className="aplcn-is-graduate">Did you graduate</h5>
          </div>
          <div className="aplcn-form-row-main">
            <ul className="aplcn-checklist inline-radios">
              <li>
                <input
                  value="yes"
                  type="radio"
                  id="is_degree_graduate88"
                  name="form.is_degree_graduate"
                  onChange={this.setValue.bind(this)}
                  onBlur={this.blur.bind(this)}
                  checked={this.state.form.is_degree_graduate === "yes"}
                />
                <label htmlFor="is_degree_graduate88">
                  <span className="aplcn-check-label">Yes</span>
                </label>
              </li>
              <li>
                <input
                  value="no"
                  type="radio"
                  id="is_degree_graduate222"
                  name="form.is_degree_graduate"
                  onChange={this.setValue.bind(this)}
                  onBlur={this.blur.bind(this)}
                  checked={this.state.form.is_degree_graduate !== "yes"}
                />
                <label htmlFor="is_degree_graduate222">
                  <span className="aplcn-check-label">No</span>
                </label>
              </li>
            </ul>
          </div>

          <div className="aplcn-form-section-head">
            <h4>University / Tech School</h4>
          </div>

          <div className="aplcn-form-row-main  aplcn-has-inputs">
            <div className="row aplcn-row">
              <div className="col-sm-12 col-md-4 aplcn-inp-wrap">
                <input
                  type="text"
                  placeholder="University/tech name and location*"
                  onChange={this.setValue.bind(this)}
                  onBlur={this.blur.bind(this)}
                  value={this.state.form.university1}
                  name="form.university1"
                />
              </div>
              <div className="col-sm-12 col-md-4 aplcn-inp-wrap">
                <input
                  type="text"
                  placeholder="No. years/units completed"
                  onChange={this.setValue.bind(this)}
                  onBlur={this.blur.bind(this)}
                  value={this.state.form.degree_duration1}
                  name="form.degree_duration1"
                />
              </div>
              <div className="col-sm-12 col-md-4 aplcn-inp-wrap">
                <input
                  type="text"
                  placeholder="Degreee/course taken"
                  onChange={this.setValue.bind(this)}
                  onBlur={this.blur.bind(this)}
                  value={this.state.form.degree_taken1}
                  name="form.degree_taken1"
                />
              </div>
            </div>
          </div>

          <div className="aplcn-form-section-head">
            <h5 className="aplcn-is-graduate">Did you graduate</h5>
          </div>
          <div className="aplcn-form-row-main">
            <ul className="aplcn-checklist inline-radios">
              <li>
                <input
                  value="yes"
                  type="radio"
                  id="is_degree_graduate107"
                  name="form.is_degree_graduate1"
                  onChange={this.setValue.bind(this)}
                  onBlur={this.blur.bind(this)}
                  checked={this.state.form.is_degree_graduate1 === "yes"}
                />
                <label htmlFor="is_degree_graduate107">
                  <span className="aplcn-check-label">Yes</span>
                </label>
              </li>
              <li>
                <input
                  value="no"
                  type="radio"
                  id="is_degree_graduate16377"
                  name="form.is_degree_graduate1"
                  onChange={this.setValue.bind(this)}
                  onBlur={this.blur.bind(this)}
                  checked={this.state.form.is_degree_graduate1 !== "yes"}
                />
                <label htmlFor="is_degree_graduate16377">
                  <span className="aplcn-check-label">No</span>
                </label>
              </li>
            </ul>
          </div>

          <div className="aplcn-form-section-head">
            <h4>Additional Information</h4>
          </div>

          <div className="aplcn-form-row-main  aplcn-has-inputs">
            <p className="aplcn-desc">
              Present below any other information which relates to your ability
              to perform the job for which you are applying such as licenses,
              memberships, language skills, academic and athletic achievements,
              community involvement, and volunteer experiences. Leave out
              information indicating religion, gender or any other protected
              category.
            </p>
            <div className="aplcn-note-text-wrapper">
              <textarea
                name="form.addition_info"
                placeholder="Please enter additional information here…"
                value={this.state.form.addition_info}
                onChange={this.setValue.bind(this)}
                onBlur={this.blur.bind(this)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 aplcn-form-main-actionbar">
              <div className="aplcn-form-actionbar">
                <button
                  onClick={this.back.bind(this)}
                  type="button"
                  disabled=""
                  className="aplcn-prev-button"
                >
                  Previous
                </button>
                <button type="submit" className="aplcn-next-btn">
                  Next
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
  back(event) {
    event.preventDefault();
    this.props.back(step);
  }
}
function mapStateToProps(state) {
  return state;
}
const connectedApp = connect(mapStateToProps)(Qualification);
export { connectedApp as Qualification };
